import { stringify } from "qs";

import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { ApiResponse } from "custom-types/Deals";
import logError from "utils/logError";

export const getDeals = async (
  coordinates: Coordinates,
  countryCode = "US",
  skip = 0,
  take?: number,
  query?: { [key: string]: string },
) => {
  const route = "/api/menu_deals/v1/search";

  let params = {
    ...coordinates,
    "filter[active]": true,
    skip,
    "sort[0][distance]": "asc",
    ...query,
  };

  if (take) {
    params = { ...params, ...{ take } };
  }

  const body = stringify(params, {
    arrayFormat: "brackets",
    encodeValuesOnly: true,
  });

  try {
    const {
      data: {
        data,
        metadata: { availableFilters, totalCount },
      },
    } = await consumerApi.get<ApiResponse>(`${route}?${body}`, {
      headers: {
        "x-country-code": countryCode,
      },
    });

    const dealCategories =
      availableFilters
        .find((filter) => filter.key === "categories")
        ?.options?.map((category) => category.key) || [];

    return {
      dealCategories,
      deals: data,
      totalCount,
    };
  } catch (e) {
    logError(e.message, {
      functionName: "getDeals",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return { dealCategories: [], deals: [], totalCount: 0 };
  }
};
