import { stringify } from "qs";

import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { ApiResponse, Promotion } from "custom-types/Promotions";
import logError from "utils/logError";

export const getPromotions = async (
  coordinates: Coordinates,
  countryCode = "US",
  skip = 0,
  take?: number,
) => {
  const route = "/api/feeds/v1/deals";
  let params = {
    analytics_category: "global-search",
    analytics_label: "promotions",
    format: "raw",
    platform: "web",
    ...coordinates,
    skip,
  };
  if (take) {
    params = { ...params, ...{ take } };
  }
  const body = stringify(params, {
    arrayFormat: "brackets",
    encodeValuesOnly: true,
  });

  try {
    const { data } = await consumerApi.get<ApiResponse>(`${route}?${body}`, {
      headers: {
        "x-country-code": countryCode,
      },
    });

    const mappedPromotion: Promotion[] = data.map((promo) => {
      const {
        imageUrl,
        title,
        dispensaryId,
        dispensaryName,
        dispensarySlug,
        distance,
      } = promo;

      return {
        dispensary: {
          distanceMi: distance,
          id: dispensaryId,
          name: dispensaryName,
          slug: dispensarySlug,
        },
        imageUrl: decodeURIComponent(imageUrl || ""),
        title,
      };
    });

    return { promotions: mappedPromotion };
  } catch (e) {
    logError(e.message, {
      functionName: "getPromotions",
      service: "consumer",
      statusCode: e.statusCode,
    });

    return { promotions: [] };
  }
};
