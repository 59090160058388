import React from "react";
import classNames from "classnames";

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  message: string;
}

const FormElementErrorMessage: React.FC<Props> = ({ message, ...props }) => (
  <p {...props}>
    <span className={classNames({ "text-error": !!message })}>{message}</span>
  </p>
);

export default FormElementErrorMessage;
