import React from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";

import { DeliveryCities, PopularCities } from "custom-types/Directus";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { localizeText } from "utils/localizeText";

import TrackImpression from "components/TrackImpression";

import SectionWrapper from "../SectionWrapper";

type Props = {
  cities: PopularCities["cities"] | DeliveryCities["delivery_cities"];
  className?: string;
  title?: string;
  subtitle?: string;
  gaCategory?: string; // Google Analytics Category for tracking impression/click
  gaLabel?: string; // Google Analytics Label for tracking impression/click
};

const PopularCitiesSection = ({
  cities,
  className = "",
  title = "Going somewhere?",
  subtitle = "Shop Leafly for local, legal weed, anywhere you are.",
  gaCategory,
  gaLabel,
}: Props) => {
  const countryCode = useDomainCountryCode();

  return cities?.length ? (
    <TrackImpression
      category={gaCategory || "Popular Cities Section"}
      label={gaLabel || "Popular Cities Section"}
      data-testid="popular-cities-section"
      className={className}
    >
      <SectionWrapper
        title={title}
        subtitle={subtitle}
        changeLocation={false}
        className="container"
      >
        <div className="row -mb-lg mt-md">
          {cities.map((city, i) => {
            if (city.name && city.count) {
              const citySlug = `/dispensaries/${city.name
                .toLowerCase()
                .replace(" ", "-")
                .trim()}`;
              const link = city.link || citySlug;
              return (
                <div
                  className="col-1/2 md:col-1/4 lg:col-1/6 mb-lg"
                  key={`${citySlug}-${i}`}
                >
                  <Link
                    href={link}
                    className="flex flex-col justify-between rounded shadow-low bg-white p-md h-full"
                    onClick={() => {
                      trackEvent(
                        gaCategory || "Popular Cities Card",
                        "Click",
                        gaLabel || city.name,
                      );
                    }}
                    data-testid="popular-cities-section-card"
                  >
                    <h3 className="text-green font-bold mb-xs text-sm">
                      {city.name}
                    </h3>
                    <p className="mb-none text-xs">{`${city.count} ${
                      Number(city.count) === 1
                        ? localizeText("dispensary", countryCode)
                        : localizeText("dispensaries", countryCode)
                    }`}</p>
                  </Link>
                </div>
              );
            } else {
              return <></>;
            }
          })}
        </div>
      </SectionWrapper>
    </TrackImpression>
  ) : null;
};

export default PopularCitiesSection;
