import React, {
  ChangeEvent,
  FormEvent,
  forwardRef,
  MutableRefObject,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";

import { Themes } from "custom-types/Themes";

import FormElementErrorMessage from "components/botanic/FormElementErrorMessage";
import FormElementLabel from "components/botanic/FormElementLabel";
import FormField from "components/FormField";

const noop = () => {};

export type InputProps = {
  autoComplete?: string;
  activeOnLoad?: boolean;
  className?: string;
  errorMessage?: string;
  errorMessageClasses?: string;
  helperText?: string;
  id: string;
  inputClasses?: string;
  label?: string | JSX.Element;
  labelClasses?: string;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  onFocus?: (e: FormEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  theme?: Themes.light;
  type?: string;
  value?: string;
  disabled?: boolean;
  name?: string;
  pattern?: string;
  required?: boolean;
  style?: Record<string, string | number>;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoComplete,
      activeOnLoad = false,
      className = "",
      errorMessage,
      errorMessageClasses,
      helperText,
      id,
      inputClasses = "",
      label,
      labelClasses,
      onBlur = noop,
      onFocus = noop,
      onChange = noop,
      placeholder = "",
      theme,
      type = "text",
      value,
      disabled,
      name,
      pattern,
      required,
      ...others
    },
    ref,
  ) => {
    const [isActive, setIsActive] = useState(activeOnLoad);

    const newRef: MutableRefObject<HTMLInputElement | null> =
      React.useRef<HTMLInputElement>(null);
    const textInput = (ref || newRef) as MutableRefObject<HTMLInputElement>;

    useEffect(() => {
      if (textInput?.current && !isActive) {
        setIsActive(textInput.current?.value?.length > 0);
      }
    }, [isActive, textInput]);

    return (
      <FormField
        theme={theme}
        className={classNames(className, { "form__field--active": isActive })}
      >
        {label ? (
          <FormElementLabel
            className={labelClasses}
            hasError={!!errorMessage}
            label={label}
            htmlFor={id}
          />
        ) : (
          <></>
        )}

        <input
          id={id}
          name={name}
          ref={textInput}
          onFocus={(e) => {
            setIsActive(true);
            onFocus(e);
          }}
          placeholder={isActive ? undefined : label ? undefined : placeholder}
          onBlur={(e) => {
            onBlur(e);
            setIsActive(textInput?.current?.value?.length > 0);
          }}
          type={type}
          className={inputClasses}
          value={value}
          onChange={onChange}
          pattern={pattern}
          disabled={disabled}
          required={required}
          autoComplete={autoComplete}
          {...others}
        />
        {helperText && !errorMessage ? <p>{helperText}</p> : <></>}
        {errorMessage ? (
          <FormElementErrorMessage
            className={errorMessageClasses}
            message={errorMessage}
          />
        ) : (
          <></>
        )}
      </FormField>
    );
  },
);

export default Input;
