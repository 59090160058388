import "intersection-observer";

import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

/*
NOTE: This hook should be deprecated and just use the useInView hook from react-intersection-observer https://www.npmjs.com/package/react-intersection-observer

At it's core, this hook takes a function as an argument and returns an array with a ref to be attached to another component.
When that component comes into view for a user, the function will be called.
NOTE: The function given will only be called the first time the component comes into view.
      This is expected behavior as we mainly use this for impression event tracking and don't want to fire off false multiples of impressions

It uses react-intersection-observer in order to accomplish this.

The second argument is an options argument that gets passed directly to react-intersection-observer.

The returned array also has three other properties:
inView: Boolean for when the element is inView
entry: And object returned from react-intersection-observer with properties such as:
  boundingClientRect
  intersectionRatio
  intersectionRect
  isIntersecting
  isVisible
  rootBounds
  target
  time
tracked: Boolean for if the tracking function has been fired or not
*/
type ReturnProps = [
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
  any,
  boolean,
  IntersectionObserverEntry | undefined,
  boolean,
];

export default function useOnComponentInView(
  onInViewFunc = () => {},
  options?: Record<string, string | number | boolean>,
): ReturnProps {
  const [ref, inView, entry] = useInView(options);
  const [tracked, setTracked] = useState(false);

  useEffect(() => {
    const hasBeenTracked = tracked;
    if (!hasBeenTracked && inView) {
      onInViewFunc();
      setTracked(true);
    }
  }, [inView, tracked, onInViewFunc]);

  return [ref, inView, entry, tracked];
}
