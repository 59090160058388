import React, { useState } from "react";
import { validate } from "email-validator";
import Link from "next/link";

import { Themes } from "custom-types/Themes";

import Button from "components/botanic/Button";
import Input from "components/botanic/Input";

const EmailSignup: React.FC<{
  onSubmit: (email: string) => void;
  className?: string;
  id: string;
}> = ({ className, onSubmit, id }) => {
  const [email, setEmail] = useState<string>();
  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email?: string) => validate(String(email));

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    if (
      (!isValidEmail && validateEmail(e.target.value)) ||
      e.target.value === ""
    ) {
      // edge case to remove error messaging if user is typing a new, valid email after an initial invalid one
      setIsValidEmail(true);
    }
  };

  const validateAndSubmit = () => {
    if (email && validateEmail(email)) {
      setIsValidEmail(true);
      onSubmit(email);
    } else {
      setIsValidEmail(false);
    }
  };

  return (
    <div className={className}>
      <label htmlFor={id} className="text-xs font-bold">
        Email address
      </label>
      <div className="flex rounded h-[48px] email-signup">
        <Input
          autoComplete="email"
          id={id}
          inputClasses="placeholder:text-grey placeholder:text-sm h-full rounded-l rounded-r-none border-l border-t border-b border-r-0 border-light-grey text-sm font-bold"
          labelClasses="text-grey pointer-events-none"
          placeholder="email@address.com"
          onChange={onInputChange}
          theme={Themes.light}
          type="email"
        />
        <Button
          className="h-full text-green text-sm bg-white rounded-l-none rounded-r flex-shrink-0 px-md border-l-0 border-t border-r border-b border-light-grey"
          onClick={() => validateAndSubmit()}
          variant="text"
        >
          sign up
        </Button>
      </div>
      {!isValidEmail && (
        <div className="text-error text-xs">Please enter a valid email</div>
      )}
      <p className="text-xs text-grey mt-sm">
        By providing us with your email address, you agree to Leafly's&nbsp;
        <Link href="/info/terms-of-use">Terms of Service</Link>
        &nbsp;and&nbsp;
        <Link href="/info/privacy-policy">Privacy Policy</Link>.
      </p>
    </div>
  );
};

export default EmailSignup;
