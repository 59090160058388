import React, { useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import { useSelector } from "react-redux";

import addEmailSignup from "api/requests/addEmailSignup";
import { getLocationState } from "redux/selectors/location";

import EmailSignup from "components/botanic/EmailSignup";

type DealsTakeoverNoResultsProps = {
  gaCategory: string; // the Category to track in Google Analytics for impression/click
  signupSource: string; // the 'source' field if  user signs up for email from this component
};

const DealsTakeoverNoResults = ({
  gaCategory,
  signupSource,
}: DealsTakeoverNoResultsProps) => {
  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
  const userLocation = useSelector(getLocationState);

  const submitEmailSignup = async (email: string) => {
    await addEmailSignup(email, userLocation, signupSource);

    setIsEmailSubmitted(true);

    trackEvent(gaCategory, "Click", "No deals email sign up");
  };

  return (
    <div className="mt-xs text-md" data-testid="deals-takeover-no-results">
      <span className="font-extrabold">
        Want to find weed deals near you and more?{" "}
      </span>
      Let’s connect.
      {isEmailSubmitted ? (
        <div className="mt-md">Thanks for signing up!</div>
      ) : (
        <EmailSignup
          onSubmit={submitEmailSignup}
          id="deals-takeover-email-signup"
        />
      )}
    </div>
  );
};

export default DealsTakeoverNoResults;
