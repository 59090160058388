import React, { MouseEventHandler } from "react";
import { trackEvent } from "@leafly-com/web-utils";
import Link from "next/link";
import { useDispatch, useSelector } from "react-redux";

import { REQUEST_LOCATION } from "redux/reducers/location";
import { getFormattedLocation } from "redux/selectors/location";

type Props = {
  title: string;
  subtitle?: string;
  link?: string;
  linkText?: string;
  onClickEvents?: MouseEventHandler<HTMLAnchorElement>;
  changeLocation?: boolean;
  className?: string;
  children: JSX.Element;
};

const SectionWrapper = ({
  title,
  subtitle,
  link,
  linkText,
  onClickEvents,
  changeLocation = true,
  className,
  children,
}: Props) => {
  const formattedLocation = useSelector(getFormattedLocation);
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch({ type: REQUEST_LOCATION });
    trackEvent("Change location button", "Click", title);
  };

  return (
    <div className={className} data-testid="section-wrapper">
      <div className="flex justify-between items-end">
        <div>
          {title && title.trim().length > 0 && (
            <h2 data-testid="section-wrapper-title">{title}</h2>
          )}
          {subtitle && subtitle.trim().length > 0 ? (
            <p className="mb-none" data-testid="section-wrapper-subtitle">
              {subtitle}
              {changeLocation ? (
                <>
                  {formattedLocation ? ` ${formattedLocation}` : " your city"} (
                  <button
                    onClick={onClickHandler}
                    className="font-bold pointer text-green"
                    data-testid="section-wrapper-location-change"
                  >
                    change
                  </button>
                  )
                </>
              ) : null}
            </p>
          ) : null}
        </div>
        {link && (
          <Link
            className="font-bold hidden md:block text-green"
            href={link}
            onClick={onClickEvents}
            data-testid="section-wrapper-link"
          >
            {linkText}
          </Link>
        )}
      </div>
      {children}
      {link && (
        <Link
          className="mt-sm font-bold block md:hidden text-green"
          href={link}
          onClick={onClickEvents}
        >
          {linkText}
        </Link>
      )}
    </div>
  );
};

export default SectionWrapper;
