import { Coordinates } from "custom-types/Coordinates";
import logError from "utils/logError";

import { getDeals } from "./getDeals";
import { getPromotions } from "./getPromotions";

const getDealsCount = async (coordinates: Coordinates, countryCode: string) => {
  try {
    const [dealsResponse, promotionsResponse] = await Promise.all([
      getDeals(coordinates, countryCode),
      getPromotions(coordinates, countryCode),
    ]);
    const menuDealsCount = dealsResponse?.totalCount || 0;
    const promotionsCount = promotionsResponse?.promotions?.length || 0;
    const count = Number(menuDealsCount) + Number(promotionsCount);

    return { count };
  } catch {
    logError("Error processing data in getDealsCount");
    return { count: 0 };
  }
};
export default getDealsCount;
